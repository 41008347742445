/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AuthContext } from 'src/context/AuthenticationContext'

import { Permissions } from 'src/domain/models/permissions'

import {
  PermissionCodeAccess,
  ControlPanelCodeAccess,
  MarketplaceCodeAccess,
  MARKETPLACE_PERMISSION_TOKEN,
  CONTROL_PANEL_PERMISSION_TOKEN,
  RECOVER_PERMISSION_TOKEN,
  CMS_PERMISSION_TOKEN,
  MONITOR_PERMISSION_TOKEN,
  ComplianceCodeAccess,
  MonitorCodeAccess,
} from 'src/utils/constants'

import axios from 'axios'
import { ACCESSDENIED } from './routes'
import Main from '../main/routes/Main'

import LoaderPage from '../presentation/components/LoaderPage'
import { useCustomQuery } from 'src/infra/reactQuery'
import { GetAccessToken } from 'src/utils/helper'
import { permissionReduce } from 'src/utils/common'
import { CONTROLPANEL_URL } from 'src/infra/http/httpClient'

const SwitchRouteProtect = () => {
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [permissionCodesCP, setPermissionCodesCP] = useState<any[]>([])
  const [permissionCodesMarketplace, setPermissionCodesMarketplace] = useState<
    any[]
  >([])
  const [permissionCodesCompliance, setPermissionCodesCompliance] = useState<
    any[]
  >([])
  const [permissionCodesMonitoring, setPermissionCodesMonitoring] = useState<
    any[]
  >([])
  const [managePermission, setManagePermission] = useState<Permissions[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [encodedPermissionsCP, setEncodedPermissionsCP] = useState('')
  const [encodedPermissionsMarketplace, setEncodedPermissionsMarketplace] =
    useState('')
  const [encodedPermissionsCompliance, setEncodedPermissionsCompliance] =
    useState('')
  const [encodedPermissionsMonitoring, setEncodedPermissionsMonitoring] =
    useState('')
  const [loadingPermission, setLoadingPermission] = useState(true)
  const { user, handleSetManagePermissions } = useContext<any>(AuthContext)
  const userToken = GetAccessToken()
  const [permissionsFetched, setPermissionsFetched] = useState(false)

  const {
    data: permissionData,
    isFetching: loading,
    isFetched: isFechtedData,
  } = useCustomQuery<any>(
    ['permissions', permissionCodes, encodedPermissions],
    async () => {
      return (
        permissionCodes &&
        encodedPermissions &&
        axios({
          method: 'post',
          url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
          data: {
            permissionCodes,
            encodedPermissions,
          },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          if (result?.data?.permissions) {
            handleSetManagePermissions?.((prev: any) => [
              ...prev,
              ...result.data.permissions,
            ])
          }
          return result.data
        })
      )
    },
    { enabled: !!(permissionCodes && encodedPermissions) }
  )

  const { data: permissionDataCP, isFetching: loadingCP } = useCustomQuery<any>(
    ['permissionsCP', permissionCodesCP, encodedPermissionsCP],
    async () => {
      return (
        permissionCodesCP &&
        encodedPermissionsCP &&
        axios({
          method: 'post',
          url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
          data: {
            permissionCodes: permissionCodesCP,
            encodedPermissions: encodedPermissionsCP,
          },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          if (result?.data?.permissions) {
            handleSetManagePermissions?.((prev: any) => [
              ...prev,
              ...result.data.permissions,
            ])
          }

          return result.data
        })
      )
    },
    { enabled: !!(isFechtedData && permissionCodesCP && encodedPermissionsCP) }
  )

  const { data: permissionDataMarketpkace, isFetching: loadingRecover } =
    useCustomQuery<any>(
      [
        'permissionsMarketplace',
        permissionCodesMarketplace,
        encodedPermissionsMarketplace,
      ],
      async () => {
        return (
          permissionCodesMarketplace &&
          encodedPermissionsMarketplace &&
          axios({
            method: 'post',
            url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
            data: {
              permissionCodes: permissionCodesMarketplace,
              encodedPermissions: encodedPermissionsMarketplace,
            },
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            if (result?.data?.permissions) {
              handleSetManagePermissions?.((prev: any) => [
                ...prev,
                ...result.data.permissions,
              ])
            }

            return result.data
          })
        )
      },
      {
        enabled: !!(
          isFechtedData &&
          permissionCodesMarketplace &&
          encodedPermissionsMarketplace
        ),
      }
    )

  const { data: permissionDataCompliance, isFetching: loadingCompliance } =
    useCustomQuery<any>(
      [
        'permissionsComplaince',
        permissionCodesCompliance,
        encodedPermissionsCompliance,
      ],
      async () => {
        return (
          permissionCodesCompliance &&
          encodedPermissionsCompliance &&
          axios({
            method: 'post',
            url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
            data: {
              permissionCodes: permissionCodesCompliance,
              encodedPermissions: encodedPermissionsCompliance,
            },
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            if (result?.data?.permissions) {
              handleSetManagePermissions?.((prev: any) => [
                ...prev,
                ...result.data.permissions,
              ])
            }

            return result.data
          })
        )
      },
      {
        enabled: !!(
          isFechtedData &&
          permissionCodesCompliance &&
          encodedPermissionsCompliance
        ),
      }
    )

  const { data: permissionDataMonitoring, isFetching: loadingMonitoring } =
    useCustomQuery<any>(
      [
        'permissionsMonitoring',
        permissionCodesMonitoring,
        encodedPermissionsMonitoring,
      ],
      async () => {
        return (
          permissionCodesMonitoring &&
          encodedPermissionsMonitoring &&
          axios({
            method: 'post',
            url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
            data: {
              permissionCodes: permissionCodesMonitoring,
              encodedPermissions: encodedPermissionsMonitoring,
            },
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            if (result?.data?.permissions) {
              handleSetManagePermissions?.((prev: any) => [
                ...prev,
                ...result.data.permissions,
              ])
            }

            return result.data
          })
        )
      },
      {
        enabled: !!(
          isFechtedData &&
          permissionCodesMonitoring &&
          encodedPermissionsMonitoring
        ),
      }
    )

  const recoverPermissions = permissionData?.permissions
  const cpPermissions = permissionDataCP?.permissions
  const marketplacePermissions = permissionDataMarketpkace?.permissions
  const compliancePermissions = permissionDataCompliance?.permissions
  const monitoringPermissions = permissionDataMonitoring?.permissions

  useEffect(() => {
    if (recoverPermissions) {
      setManagePermission(recoverPermissions)
      setPermissionsFetched(true)
    }
    if (cpPermissions) {
      setManagePermission(managePermission?.concat(cpPermissions))
    }
    if (marketplacePermissions) {
      setManagePermission(managePermission?.concat(marketplacePermissions))
    }
    if (compliancePermissions) {
      setManagePermission(managePermission?.concat(compliancePermissions))
    }
    if (monitoringPermissions) {
      setManagePermission(managePermission?.concat(monitoringPermissions))
    }
  }, [
    recoverPermissions,
    cpPermissions,
    marketplacePermissions,
    compliancePermissions,
    monitoringPermissions,
  ])

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      const permissionCodeCP: any[] = Object.entries(
        ControlPanelCodeAccess
      ).map(([pName, pCode]) => pCode)
      const permissionCodeMarketplace: any[] = Object.entries(
        MarketplaceCodeAccess
      ).map(([pName, pCode]) => pCode)
      const permissionCodeCompliance: any[] = Object.entries(
        ComplianceCodeAccess
      ).map(([pName, pCode]) => pCode)
      const permissionCodeMonitoring: any[] = Object.entries(
        MonitorCodeAccess
      ).map(([pName, pCode]) => pCode)

      setPermissionCodesCP(permissionCodeCP)
      setPermissionCodes(permissionCode)
      setPermissionCodesMarketplace(permissionCodeMarketplace)
      setPermissionCodesCompliance(permissionCodeCompliance)
      setPermissionCodesMonitoring(permissionCodeMonitoring)
      setEncodedPermissions(profile[RECOVER_PERMISSION_TOKEN])
      setEncodedPermissionsCP(profile[CONTROL_PANEL_PERMISSION_TOKEN])
      setEncodedPermissionsMarketplace(profile[MARKETPLACE_PERMISSION_TOKEN])
      setEncodedPermissionsCompliance(profile[CMS_PERMISSION_TOKEN])
      setEncodedPermissionsMonitoring(profile[MONITOR_PERMISSION_TOKEN])
      setLoadingPermission(false)
    }
  }, [user])

  if (
    (user && user.isAuthenticated && !managePermission && loadingPermission) ||
    loading ||
    loadingCP ||
    loadingRecover ||
    loadingCompliance ||
    loadingMonitoring
  ) {
    return <LoaderPage />
  }

  if (
    user &&
    !loadingPermission &&
    (!permissionReduce(managePermission)[PermissionCodeAccess.Recover] ||
      permissionReduce(managePermission)[PermissionCodeAccess.Recover] ===
        undefined)
  ) {
    return <Redirect to={ACCESSDENIED} />
  }

  return (
    <Switch>
      <Route>
        {permissionsFetched && (
          <Main managePermission={managePermission || []} />
        )}
      </Route>
    </Switch>
  )
}

export default SwitchRouteProtect
