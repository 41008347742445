import { BACKEND_URL, ACCOUNT_URL } from 'src/infra/http/httpClient'

const businessPrefix = 'businesses.'
const postPlacementPrefix = 'postPlacement.'

export const BusinessGetCreditors = `${BACKEND_URL}${businessPrefix}getCreditors`
export const BusinessGetVendors = `${BACKEND_URL}${businessPrefix}getVendors`
export const BusinessGetCreditorsRelated = `${BACKEND_URL}${businessPrefix}getCreditorsRelated`
export const BusinessGetVendorsRelated = `${BACKEND_URL}${businessPrefix}getVendorsRelated`
export const BusinessGetVendorsByStrategyAndStage = `${BACKEND_URL}${businessPrefix}getVendorsByStrategyAndStage`
export const BusinessGetVendorsByStrategy = `${BACKEND_URL}${businessPrefix}getVendorsByStrategy`
export const BusinessGetPlacedBusiness = `${BACKEND_URL}${businessPrefix}getPlacedBusiness`
export const BusinessGetBusinessSettings = `${BACKEND_URL}${businessPrefix}getBusinessSettings`
export const BusinessGetBusinessesSettings = `${BACKEND_URL}${businessPrefix}getBusinessesSettings`
export const BusinessGetBusinessWithoutBusinessSettings = `${BACKEND_URL}${businessPrefix}getBusinessWithoutBusinessSettings`
export const PostPlacementGetTypes = `${ACCOUNT_URL}${postPlacementPrefix}getPostPlacementTypes`
