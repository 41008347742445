import React from 'react'
import { makeAccountsData } from 'src/main/factories/feature/get/Accounts'
import { makeMediaDownloadGetData } from 'src/main/factories/feature/get/MediaDownload'
import { makeTransactionPostData } from 'src/main/factories/feature/post/Transaction'
import AccountDetail from 'src/presentation/pages/Accounts/AccountDetails'

const makeAccountDetail: React.FC = () => {
  return (
    <AccountDetail
      accountOperations={makeAccountsData()}
      transactionPostOperation={makeTransactionPostData()}
      mediaFilesOperation={makeMediaDownloadGetData()}
    />
  )
}

export default makeAccountDetail
