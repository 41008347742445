import { Button, Flex } from 'everchain-uilibrary'
import React from 'react'

export const complaintSearchAccountsColumns = (
  onClickCreateButton: (ecaid: string) => void
) => {
  return [
    {
      title: 'Action',
      width: 70,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <td>
            <Flex justifyContent="center">
              <Button onClick={() => onClickCreateButton(props.dataItem.ecaid)}>
                Create
              </Button>
            </Flex>
          </td>
        )
      },
    },
    {
      field: 'lender',
      title: 'Lender',
      show: true,
      width: 200,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      show: true,
      width: 200,
    },
    {
      field: 'firstName',
      title: 'First Name',
      show: true,
      width: 150,
    },
    {
      field: 'lastName',
      title: 'Last Name',
      show: true,
      width: 150,
    },
    {
      field: 'ecaid',
      title: 'ECAID',
      show: true,
      width: 250,
      filter: 'text',
    },
  ]
}
