import React from 'react'
import { Display } from '../styles'
import { AccountDetailsData } from 'src/domain/models/accounts'
import {
  Card,
  Content,
  Summary,
  fFormatDate,
  fNumberCurrency,
} from 'everchain-uilibrary'
import { profileCountry } from 'src/utils/user'

interface DisplayAggregatesProps {
  account: AccountDetailsData | undefined
  isLoading: boolean
}

export interface SummaryValue {
  header: string
  value: string | null
  order: number
}

const DisplayAggregates: React.FC<DisplayAggregatesProps> = ({
  account,
  isLoading,
}: DisplayAggregatesProps) => {
  const aggregateBlock: SummaryValue[] = [
    {
      header: 'Original Balance',
      value: fNumberCurrency(
        typeof account?.totalBalance === 'number'
          ? account?.totalBalance.toFixed(2)
          : '',
        profileCountry()
      ),
      order: 0,
    },
    {
      header: 'Recovered Balance',
      value: fNumberCurrency(
        account?.totalRecoveryPayments
          ? (account?.totalRecoveryPayments).toFixed(2)
          : 0,
        profileCountry()
      ),
      order: 1,
    },
    {
      header: 'Current Balance',
      value: fNumberCurrency(
        typeof account?.currentTotalBalance === 'number'
          ? account?.currentTotalBalance.toFixed(2)
          : '',
        profileCountry()
      ),
      order: 2,
    },
  ]

  const aggregateGrid: SummaryValue[] = [
    {
      header: 'Principal',
      value: fNumberCurrency(
        account?.principalBalance ? account?.principalBalance.toFixed(2) : '',
        profileCountry()
      ),
      order: 1,
    },
    {
      header: 'Interest',
      value: fNumberCurrency(
        account?.interestBalance ? account?.interestBalance.toFixed(2) : '',
        profileCountry()
      ),
      order: 2,
    },
    {
      header: 'Fee',
      value: fNumberCurrency(
        account?.feeBalance ? account?.feeBalance.toFixed(2) : '',
        profileCountry()
      ),
      order: 3,
    },
    {
      header: 'Default Date',
      value: account?.defaultDate ? fFormatDate(account?.defaultDate) : null,
      order: 4,
    },
    {
      header: 'Fund Date',
      value: account?.fundDate ? fFormatDate(account?.fundDate) : null,
      order: 5,
    },
    {
      header: 'Current Creditor',
      value: account?.lender ? account?.lender : null,
      order: 6,
    },
    {
      header: 'Current Vendor',
      value: account?.currentVendorName ? account?.currentVendorName : null,
      order: 7,
    },
  ]

  return (
    <Display data-test-id="display-aggregates">
      <Card
        variant="secondary"
        content={
          <Content padding={3}>
            <Summary
              blockValues={aggregateBlock}
              gridItemValues={aggregateGrid}
              isLoading={isLoading}
            ></Summary>
          </Content>
        }
      ></Card>
    </Display>
  )
}

export default DisplayAggregates
