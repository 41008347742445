/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { ModalDialog } from 'everchain-uilibrary'
import DownloadOptions from './DownloadOptions'
import { makeMediaDownloadGetData } from 'src/main/factories/feature/get/MediaDownload'
import { FormFilter } from '..'
import { makeMediaDownloadPostData } from 'src/main/factories/feature/post/MediaDownload'

interface DownloadModalProps {
  open: boolean
  setOpenUploadModal: any
  form: FormFilter | undefined
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  open,
  setOpenUploadModal,
  form,
}: DownloadModalProps) => {
  return (
    <>
      <ModalDialog
        isOpen={open}
        hideOkButton={true}
        hideCancelButton={true}
        onClose={() => {
          setOpenUploadModal(false)
        }}
        header="Download Bulk Media"
        style={{ minWidth: '420px', minHeight: '300px' }}
      >
        <DownloadOptions
          mediaDownloadGetOperations={makeMediaDownloadGetData()}
          mediaDownloadPostOperations={makeMediaDownloadPostData()}
          form={form}
        ></DownloadOptions>
      </ModalDialog>
    </>
  )
}

export default DownloadModal
