import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { ApiError } from 'src/domain/models/api/api'
import { FileValidationResult } from 'src/domain/models/file'
import { TransactionFileData } from 'src/domain/models/transaction'
import {
  BalanceAdjustmentProcessBalanceAdjustmentFile,
  TransactionProcessTransactionFile,
} from './urls'
import { BalanceAdjustmentsFileData } from 'src/domain/models/balance-adjustments'

export class TransactionPostData implements ITransactionPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async processTransactionFile(
    businessId: string,
    fileData: TransactionFileData[],
    fileUploaded: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append('transactionAccounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(TransactionProcessTransactionFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async processBalanceAdjustmentFile(
    businessId: string,
    fileData: BalanceAdjustmentsFileData[],
    fileUploaded: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append(
      'balanceAdjustmentAccounts',
      JSON.stringify(Array.from(fileData))
    )
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(
      BalanceAdjustmentProcessBalanceAdjustmentFile,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }
}
