import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import { PostPlacementCreateRequest } from './urls'

export class PostPlacementPostData implements IPostPlacementPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async uploadBulkFile(
    requestType: number | undefined,
    data: any[]
  ): Promise<any> {
    return this.httpClient.post(PostPlacementCreateRequest, {
      requestType: Number(requestType),
      data,
    })
  }
}
