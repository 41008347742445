import { format } from 'date-fns'
import { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'

export const dateThreeMonthsPast = () => {
  var currentDate = new Date()
  return new Date(currentDate.setDate(currentDate.getDate() - 90))
}

export const oneDayPast = () => {
  var currentDate = new Date()
  return new Date(currentDate.setDate(currentDate.getDate() - 1))
}

interface Map {
  [key: string]: string
}
const ProfileClient = () => {
  const { profileClient } = useContext(AuthContext)
  return profileClient
}

export const ConvertDateToUTC = (date: any) => {
  const year = new Date(date).getFullYear()
  const month = new Date(date).getMonth()
  const day = new Date(date).getDate()
  const hour = new Date(date).getHours()
  const minute = new Date(date).getMinutes()
  const second = new Date(date).getSeconds()

  return Date.UTC(year, month, day, hour, minute, second)
}

export const formatDateAndTimeCountry = (
  date: Date,
  country: string,
  formatType: string | undefined = undefined,
  includeSeconds: boolean | undefined = undefined,
  splitTime: boolean | undefined = undefined
): string => {
  if (date.toString() === 'Invalid Date') return 'N/A'
  const defaultCountry =
    country || ProfileClient()?.Country || process.env.REACT_APP_COUNTRY
  const f =
    formatType ||
    getLocaleDateTimeString(
      includeSeconds,
      splitTime,
      getLanguageFromCountry(defaultCountry)
    )
  return format(new Date(date), f)
}

export const getLanguageFromCountry = (country: string): string => {
  if (country === 'US') return 'en-us'
  if (country === 'UK') return 'en-gb'
  if (country === 'CA') return 'en-ca'

  return 'en-us'
}

export const getLanguage = (): string => {
  return getLanguageFromCountry(
    ProfileClient()?.Country || process.env.REACT_APP_COUNTRY
  )
}
export const getLocaleDateTimeString = (
  includeSeconds: boolean = false,
  splitTime: boolean = false,
  language: string | undefined = undefined
): string => {
  const formats: Map = {
    'fr-ca': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-ca': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-gb': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-gb-oxendict': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-us': `M/d/yyyy h:mm${includeSeconds ? ':ss' : ''} a`,
  }
  return (
    formats[language || getLanguage()] ||
    (includeSeconds
      ? splitTime
        ? 'yyyy/MM/dd | h:mm:ss a'
        : 'yyyy/MM/dd h:mm:ss a'
      : splitTime
      ? 'yyyy/MM/dd | h:mm a'
      : 'yyyy/MM/dd h:mm a')
  )
}
