import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { DataResponse } from 'src/domain/models/common'
import { DataTableState } from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { ComplaintDto } from 'src/domain/models/complaint'
import * as urls from './urls'

export class ComplaintGetData implements IComplaintGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getComplaints(
    pagination: DataTableState
  ): Promise<DataResponse<ComplaintDto>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetComplaints}?pagination=${encodedData}`
    )
    return response.data
  }

  async getPreview(ecaid: string): Promise<ComplaintDto> {
    const response = await this.httpClient.get(
      `${urls.ComplaintsGetPreview}?ecaid=${ecaid}`
    )
    return response.data
  }
}
