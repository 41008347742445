/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from 'react'
import {
  Box,
  DataTable,
  Content,
  Header,
  Flex,
  Button,
  DataTableState,
} from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { useCustomQuery } from 'src/infra/reactQuery'
import { complaintsColumns } from './gridColumns'
import { useHistory } from 'react-router-dom'
import { getStandardUri } from 'src/utils/common'
import { COMPLAINT_CREATE } from 'src/presentation/routes'

interface ComplaintProps {
  complaintOperations?: IComplaintGetOperations
}
const ComplaintList: React.FC<ComplaintProps> = ({ complaintOperations }) => {
  const history = useHistory()

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const { data: complaintsData, isLoading: loadingComplaintsData } =
    useCustomQuery(
      ['getComplaints', gridState],
      async () => complaintOperations?.getComplaints(gridState),
      { cacheTime: 0 }
    )

  const onClickCreateComplaint = () => {
    history.push(getStandardUri(COMPLAINT_CREATE))
  }

  return (
    <Content id="create-complaint-search-accounts">
      <Header title="Complaints" />
      <Flex w="100%" justifyContent="flex-end">
        <Button onClick={onClickCreateComplaint}>Create Complaint</Button>
      </Flex>
      <Box pt={4}>
        <DataTable
          isLoading={loadingComplaintsData}
          height="100%"
          maxHeight="100%"
          gridColumns={complaintsColumns}
          gridState={gridState}
          data={complaintsData?.data}
          pageable
          useFilterMenu
          total={complaintsData?.totalCount}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
          }}
        />
      </Box>
    </Content>
  )
}

export default ComplaintList
