import React, { memo, useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import Menus from './Menus'
import { Root } from './styles'
import { Box, Footer, Main, MainContent } from 'everchain-uilibrary'
import { isUkCountry } from 'src/utils/common'

import ToolbarMain from './ToolbarMain'

interface Props {
  children: React.ReactNode
}

const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  return (
    <Root>
      <ToolbarMain showSearchBar={true} />
      <Box display="flex" flexDirection="row" height="inherit">
        <Menus />
        <Main>
          <MainContent>{children}</MainContent>
          <Footer isUk={isUk} />
        </Main>
      </Box>
    </Root>
  )
}

export default memo(MainLayout)
