import { textSecondary, Typography } from 'everchain-uilibrary'
import React from 'react'

interface TypographyComplaintActionsProps {
  children?: React.ReactNode
  isLoading?: boolean
}

const TypographyComplaintActions: React.FC<TypographyComplaintActionsProps> = ({
  children,
  isLoading,
}) => {
  return (
    <Typography
      color={textSecondary.color}
      marginbottom="0px"
      isLoading={isLoading}
      skeletonWidth={100}
      skeletonHeight={20}
    >
      {children}
    </Typography>
  )
}

export default TypographyComplaintActions
