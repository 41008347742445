import React from 'react'

export const cellCreateBy = (props: any) => {
  return props.dataItem[props.field] ? (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy="created-by-field"
    >
      <span>{props.dataItem[props.field]}</span>
    </td>
  ) : (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy="created-by-field"
    >
      <span>System</span>
    </td>
  )
}
