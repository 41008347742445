/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react'
import { Button, Flex, Group, Icon } from 'everchain-uilibrary'
import { ComplaintDto } from 'src/domain/models/complaint'
import TypographyComplaintActions from './TypographyComplaintActions'

interface ComplaintActionsProps {
  complaintDto?: ComplaintDto
  isLoading?: boolean
}

const ComplaintActions: React.FC<ComplaintActionsProps> = ({
  complaintDto,
  isLoading,
}) => {
  return (
    <Flex justifyContent="space-between" pb={3} alignItems="center">
      <Group>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>ID:</strong> {complaintDto?.id || 'New'}
        </TypographyComplaintActions>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>Placer:</strong> {complaintDto?.placerName}
        </TypographyComplaintActions>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>Status:</strong> {complaintDto?.status}
        </TypographyComplaintActions>
        <TypographyComplaintActions isLoading={isLoading}>
          <strong>Vendor:</strong> {complaintDto?.vendorName}
        </TypographyComplaintActions>
      </Group>
      <Group>
        <Button
          variant="secondary"
          leftIcon={<Icon name="Block" fontSize="small" />}
          isLoading={isLoading}
        >
          Cancel
        </Button>
        <Button
          leftIcon={<Icon name="Save" fontSize="small" />}
          isLoading={isLoading}
        >
          Save
        </Button>
      </Group>
      <Group pr={3}>
        <Button
          variant="secondary"
          leftIcon={<Icon name="RemoveCircle" fontSize="small" />}
          isLoading={isLoading}
        >
          Withdraw
        </Button>
        <Button
          leftIcon={<Icon name="AddCircle" fontSize="small" />}
          isLoading={isLoading}
        >
          Submit
        </Button>
      </Group>
    </Flex>
  )
}

export default ComplaintActions
