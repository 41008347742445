/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */

import { AppBar } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'src/context/AuthenticationContext'
import { searchFilterTypeDescription } from 'src/domain/models/search'
import { SEARCH } from 'src/presentation/routes'
import { getStandardUri } from 'src/utils/common'
import { APP_NAME } from 'src/utils/constants'

const userId = window.localStorage.getItem('userId')
const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

interface Props {
  showSearchBar: Boolean
}

const ToolbarMain: React.FC<Props> = ({ showSearchBar }: Props) => {
  const history = useHistory()
  const authenticationObj = useAuth()
  const [logoutCooldown, setLogoutCooldown] = useState(false)

  const handleRedirectSearch = (search: any) => {
    history.push(
      getStandardUri(
        `${SEARCH}/1/${search.numberOfRecords}/${search.query}/${search.filter.id}`
      )
    )
  }
  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
  }

  const handleOnLogout = (): void => {
    if (!logoutCooldown) {
      setLogoutCooldown(true)
      authenticationObj.logout()
      setTimeout(() => {
        setLogoutCooldown(false)
      }, 6000)
    }
  }

  return (
    <AppBar
      appName={APP_NAME}
      logoutProps={{ onClickLogout: handleOnLogout }}
      userProps={{
        userName:
          authenticationObj.user?.profile[
            'http://debttrader.com/claims/display_name'
          ],
        onClickChangePassword: handleChangepassword,
      }}
      searchProps={
        showSearchBar && {
          filterOptions: searchFilterTypeDescription,
          onSearch: (search: any) => handleRedirectSearch(search),
        }
      }
    />
  )
}

export default ToolbarMain
