import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { DataTableState } from 'everchain-uilibrary'
import { Account } from 'src/domain/models/accounts'
import { DataResponse } from 'src/domain/models/common'
import { PostPlacementGetAccounts, PostPlacementGetTemplate } from './urls'
import { GeneratedFile } from 'src/domain/models/file'

export class PostPlacementData implements IPostPlacementGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getAccounts(
    pagination: DataTableState,
    creditorId?: string,
    vendorId?: string,
    last4SSN?: string,
    accountIDs?: string
  ): Promise<DataResponse<Account>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const last4SSNFilter = last4SSN ? `&last4SSN=${last4SSN} ` : ''
    const accountIDsFilter = accountIDs ? `&accountIDs= ${accountIDs} ` : ''
    const response = await this.httpClient.get(
      `${PostPlacementGetAccounts}?pagination=${encodedData}${vendorFilter}${creditorFilter}${last4SSNFilter}${accountIDsFilter}`
    )
    return response.data
  }

  async getTemplateFile(postPlacementType?: number): Promise<GeneratedFile> {
    const postPlacementTypeFilter = postPlacementType
      ? `templateFileType=${postPlacementType} `
      : ''
    const response = await this.httpClient.get(
      `${PostPlacementGetTemplate}?${postPlacementTypeFilter}`
    )
    return response.data
  }
}
