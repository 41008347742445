import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { MediaDownloloadPostUploadFile } from './urls'
import { IMediaDownloadPostOperations } from 'src/domain/features/post/mediaDownload/mediaDownload'
import { ApiError } from 'src/domain/models/api/api'
import { TemplateFile } from 'src/domain/models/mediaUpload'

export class MediaDownloadPostData implements IMediaDownloadPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async postUploadMediaTemplateFile(
    creditorId: any,
    vendorId: any,
    file: any
  ): Promise<TemplateFile | ApiError> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('vendorId', vendorId)
    form.append('fileUploaded', file)
    const response = await this.httpClient.post(
      MediaDownloloadPostUploadFile,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response?.data
  }
}
