import React from 'react'
import { makeAccountsData } from 'src/main/factories/feature/get/Accounts'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import ComplaintSearchAccounts from 'src/presentation/pages/Complaint/SearchAccounts'

const makeComplaintSearchAccounts: React.FC = () => {
  return (
    <ComplaintSearchAccounts
      businessOperations={makeBusinessGetData()}
      accountOperations={makeAccountsData()}
    />
  )
}

export default makeComplaintSearchAccounts
