/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { FormInput, Input } from 'everchain-uilibrary'
import { CommomInputProps } from 'src/utils/common'

interface AccountIdsInputProps extends CommomInputProps {}

const AccountIDsInput: React.FC<AccountIdsInputProps> = ({
  name = 'accountIDs',
  isForm = true,
  onBlur,
  onChange,
}) => {
  const placeholder = 'Account IDs'
  const variant = 'secondary'

  return isForm ? (
    <FormInput name={name} placeholder={placeholder} variant={variant} />
  ) : (
    <Input
      name={name}
      placeholder={placeholder}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}

export default AccountIDsInput
