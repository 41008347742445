import React, { useState } from 'react'
import {
  Button,
  Box,
  SuccessToast,
  UploadDragDrop,
  Step,
} from 'everchain-uilibrary'
import { downloadBase64File } from 'src/utils/file/fileDownload'
import { IMediaDownloadGetOperations } from 'src/domain/features/get/mediaDownload/mediaDownload'
import { FormFilter } from '..'
import { IMediaDownloadPostOperations } from 'src/domain/features/post/mediaDownload/mediaDownload'

interface DownloadOptionsProps {
  mediaDownloadGetOperations: IMediaDownloadGetOperations
  mediaDownloadPostOperations: IMediaDownloadPostOperations
  form: FormFilter | undefined
}
const DownloadOptions: React.FC<DownloadOptionsProps> = ({
  mediaDownloadGetOperations,
  mediaDownloadPostOperations,
  form,
}) => {
  const [files, setFiles] = useState<any[]>([])
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const handleDownloadAllMedia = async () => {
    setLoadingData(true)

    const template = await mediaDownloadGetOperations?.getAllAccountMedia(
      form?.creditorId,
      form?.vendorId,
      form?.dateAccountFrom,
      form?.dateAccountTo
    )
    downloadBase64File(template)
    SuccessToast('All Account Media are downloaded.')
    setLoadingData(false)
  }

  const handleDownloadMediaTemplate = async () => {
    setLoadingData(true)
    const template =
      await mediaDownloadGetOperations?.getDownloadMediaTemplateFile(
        form?.creditorId,
        form?.vendorId,
        form?.dateAccountFrom,
        form?.dateAccountTo
      )
    downloadBase64File(template)
    SuccessToast('Download Template File downloaded.')
    setLoadingData(false)
  }

  const handleUploadMediaTemplate = async () => {
    setLoadingData(true)
    const template =
      await mediaDownloadPostOperations?.postUploadMediaTemplateFile(
        form?.creditorId,
        form?.vendorId,
        files[0]
      )
    downloadBase64File(template)
    SuccessToast('Account Media are downloaded for the uploaded spreadsheet.')
    setLoadingData(false)
  }

  return (
    <>
      <Box padding={2}>
        <Button
          useRipple
          width={380}
          height={40}
          isLoading={loadingData}
          onClick={handleDownloadAllMedia}
        >
          Download All Account Media
        </Button>
      </Box>
      <Box padding={2}>
        <Button
          useRipple
          width={380}
          height={40}
          isLoading={loadingData}
          onClick={handleDownloadMediaTemplate}
        >
          Download Media Download Template
        </Button>
      </Box>
      <Box padding={2} isLoading={loadingData}>
        <Step
          title="Upload Media Download Template"
          stepNumber=""
          completed={files[0] !== undefined}
        >
          <UploadDragDrop
            files={files}
            setFiles={setFiles}
            hideUploadButton={true}
          />
        </Step>
      </Box>
      <Box padding={2} display="flex" justifyContent="flex-end">
        <Button
          useRipple
          width={140}
          height={40}
          disabled={files.length === 0}
          isLoading={loadingData}
          onClick={handleUploadMediaTemplate}
        >
          Upload File
        </Button>
      </Box>
    </>
  )
}

export default DownloadOptions
