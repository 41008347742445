import { SuccessToast, ValidationResult } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { useMutation } from '@tanstack/react-query'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'
import { BalanceAdjustmentsFileData } from 'src/domain/models/balance-adjustments'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  transactionPostOperations: ITransactionPostOperations
}

const TransactionFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  transactionPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const mutationProcessTransactionFile = useMutation({
    mutationFn: async (params: any) => {
      return transactionPostOperations.processBalanceAdjustmentFile(
        params.businessId,
        params.balanceAdjustmentFileData,
        params.file
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('fileUploaded') ||
    !stateData.hasOwnProperty('businessId')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.TransactionFile)
  const { fileData, fileUploaded, businessId } = stateData

  const mapToBalanceAdjustmentsFileObject = (
    input: any
  ): BalanceAdjustmentsFileData => {
    const dataRow: BalanceAdjustmentsFileData = {
      rowIndex: input.rowindex,
      eCAID: input.ecaid,
      lender: input.lender,
      loanid: input.loanid,
      dateMade: input.datemade,
      datePosted: input.dateposted,
      principalPayment: input.principalpayment,
      interestPayment: input.interestpayment,
      feePayment: input.feepayment,
      principalCredit: input.principalcredit,
      interestCredit: input.interestcredit,
      feeCredit: input.feecredit,
      newFee: input.newfee,
      newFeeAmount: input.newfeeamount,
    }

    return dataRow
  }

  const onSubmitFile = () => {
    setIsFetching(true)
    const balanceAdjustmentFileData: BalanceAdjustmentsFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      balanceAdjustmentFileData.push(
        mapToBalanceAdjustmentsFileObject(loweredCaseObject)
      )
    })

    const params = {
      businessId: businessId,
      balanceAdjustmentFileData,
      file: fileUploaded,
    }

    mutationProcessTransactionFile.mutate(params)
  }

  return (
    <ValidationResult
      fileMap={fileMap}
      fileData={fileData}
      fileOptions={{
        validateTotalBalance: false,
        formatDate: profileCountry(),
        validateTotalAmount: false,
        ignoreUniqueKeysValidation: true,
      }}
      processmentErrors={processmentErrors}
      isFetchingProceed={isFetching}
      onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
      onClickProceedButton={onSubmitFile}
    />
  )
}

export default TransactionFileValidation
