import {
  DataTable,
  DataTableState,
  renderCurrency,
  renderDate,
} from 'everchain-uilibrary'
import React, { useEffect, useState } from 'react'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { profileCountry } from 'src/utils/user'

interface AccountActivityProps {
  eCAID: string
  accountOperations: IAccountGetOperations
  isCardLoading: boolean
  transactionPostOperations: ITransactionPostOperations
  businessIds: any
}

const AccountActivity: React.FC<AccountActivityProps> = ({
  eCAID,
  accountOperations,
  isCardLoading,
}: AccountActivityProps) => {
  const [form, setForm] = useState<any>({
    accountActivity: [],
    totalAccountActivity: 0,
    isLoading: false,
  })

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 100,
    filter: undefined,
    sort: undefined,
  })

  const fetchActivity = async (gridParams: DataTableState) => {
    try {
      setForm((prevObj: any) => {
        return { ...prevObj, isLoading: true }
      })

      const dataResponse = await accountOperations?.getAccountActivity(
        gridParams,
        eCAID
      )

      setForm((prevObj: any) => {
        return {
          ...prevObj,
          accountActivity: dataResponse?.data,
          totalAccountActivity: dataResponse?.totalCount ?? 0,
          isLoading: false,
        }
      })
    } catch (err) {
      console.log(err)
      setForm((prevObj: any) => {
        return { ...prevObj, isLoading: false }
      })
    }
  }

  const columns = [
    {
      field: 'recordType',
      title: 'Record Type',
      show: true,
      width: 250,
    },
    { field: 'createdBy', title: 'Created By', show: true, width: 100 },
    {
      field: 'principalPayment',
      title: 'Principal Payment',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'interestPayment',
      title: 'Interest Payment',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'feePayment',
      title: 'Fee Payment',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'principalCredit',
      title: 'Principal Credit',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'interestCredit',
      title: 'Interest Credit',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'feeCredit',
      title: 'Fee Credit',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'newFee',
      title: 'New Fee',
      show: true,
      width: 100,
    },
    {
      field: 'newFeeAmount',
      title: 'New Fee Amount',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'dateMade',
      title: 'Date Made',
      show: true,
      width: 100,
      render: renderDate,
    },
  ]

  useEffect(() => {
    if (!isCardLoading) {
      fetchActivity(gridState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardLoading])

  return (
    <div data-test-id="accountActivity">
      <DataTable
        isLoading={form.isLoading || isCardLoading}
        height="100%"
        maxHeight="100%"
        gridColumns={columns || []}
        gridState={gridState}
        data={form.accountActivity}
        pageable={true}
        total={form.totalAccountActivity}
        onDataStateChange={(e) => {
          setGridState(e.dataState)
          if (form.chainOfPlacements) {
            fetchActivity(e.dataState)
          }
        }}
      />
    </div>
  )
}

export default AccountActivity
