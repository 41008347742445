import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DataTable, Icon, SuccessToast } from 'everchain-uilibrary'
import { MediaFileDetailsData } from 'src/domain/models/mediaFiles'
import ZipIcon from 'src/presentation/components/Icons/ZipIcon'
import { ConvertDateToUTC, formatDateAndTimeCountry } from 'src/utils/date'
import { makeMediaDownloadGetData } from 'src/main/factories/feature/get/MediaDownload'
import { cellCreateBy } from 'src/utils/gridHelper'

interface AccountDetailMediaFilesTableProps {
  mediaFilesData?: MediaFileDetailsData[] | undefined
  loadingTable: boolean
}

const AccountDetailMediaFilesTable: React.FC<
  AccountDetailMediaFilesTableProps
> = ({ mediaFilesData, loadingTable }: AccountDetailMediaFilesTableProps) => {
  const [mediaFileToBeDeleted, setMediaFileToBeDeleted] = useState<any>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { profileClient } = useContext<any>(AuthContext)
  const mediaDownloadGetOperations = makeMediaDownloadGetData()
  const [loadingDeleteMediaFile, setLoadingDeleteMediaFile] =
    useState<boolean>(false)
  const [loadingDownloadMediaFile, setLoadingDownloadMediaFile] =
    useState<boolean>(false)

  const onDownload = async (mediaFileId: any) => {
    setLoadingDownloadMediaFile(true)

    const mediaFile = await mediaDownloadGetOperations?.getMediaFile(
      mediaFileId
    )
    downloadFile(mediaFile.fileUri)
    setLoadingDownloadMediaFile(false)
    SuccessToast('File Deleted Successfully')
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const handleDeleteDialogToggle = () => {
    setDeleteDialogOpen(!deleteDialogOpen)
  }

  const handleDeleteMediaFile = (value: any) => {
    setMediaFileToBeDeleted(value)
    handleDeleteDialogToggle()
  }

  const onDelete = async () => {
    setLoadingDeleteMediaFile(true)

    await mediaDownloadGetOperations?.deleteMediaFile(mediaFileToBeDeleted)
    setLoadingDeleteMediaFile(false)
    SuccessToast('File Deleted Successfully')
    handleDeleteDialogToggle()
  }

  const fileLinkColumns: any[] = [
    {
      title: 'Document Type',
      field: 'documentType',
      show: true,
      width: 60,
      render: (props: any) => {
        return props.dataItem[props.field] ? (
          <td {...props} data-cy="document-type-field">
            <span>
              {props.dataItem[props.field] !== ''
                ? props.dataItem[props.field]
                : 'N/A'}
            </span>
          </td>
        ) : (
          <td data-cy="document-type-field">
            <span>N/A</span>
          </td>
        )
      },
    },
    {
      title: 'Original File Name',
      field: 'displayName',
      width: 150,
      show: true,
      render: (props: any) => {
        return (
          <td>
            <Box width={300}>
              <Button
                data-cy="file-name-button"
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                startIcon={
                  <Box width={20} height={20}>
                    <ZipIcon width="130%" height="130%" />
                  </Box>
                }
                onClick={() => {
                  onDownload(props.dataItem.id)
                }}
              >
                {props.dataItem[props.field]}
              </Button>
            </Box>
          </td>
        )
      },
    },
    {
      title: 'Uploaded By',
      field: 'createdByUserName',
      width: 150,
      show: true,
      render: (props: any) => cellCreateBy(props),
    },
    {
      title: 'Uploaded Date',
      field: 'createdUtc',
      width: 100,
      show: true,
      render: (props: any) => {
        return props.dataItem[props.field] ? (
          <td data-cy="uploaded-date-field">
            <span>
              {props.dataItem[props.field] !== ''
                ? formatDateAndTimeCountry(
                    new Date(ConvertDateToUTC(props.dataItem[props.field])),
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                : 'N/A'}
            </span>
          </td>
        ) : (
          <td data-cy="uploaded-date-field">
            <span>N/A</span>
          </td>
        )
      },
    },
    {
      title: 'Is Deleted',
      field: 'isDeleted',
      width: 50,
      show: true,
      render: (props: any) => {
        return (
          <td data-cy="is-deleted">
            <span>{props.dataItem[props.field] === true ? 'Yes' : 'No'}</span>
          </td>
        )
      },
    },
    {
      title: 'Actions',
      field: '',
      show: true,
      width: 40,
      alignCenter: true,
      render: (props: any) => {
        return (
          <td>
            <Box
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleDeleteMediaFile(props.dataItem.id)
                }}
              >
                <Icon name="Delete" fontSize="small" />
              </IconButton>
            </Box>
          </td>
        )
      },
    },
  ]

  return (
    <>
      <Box p={2}>
        <DataTable
          scrollable="scrollable"
          data={mediaFilesData}
          isLoading={
            loadingTable || loadingDownloadMediaFile || loadingDeleteMediaFile
          }
          gridColumns={fileLinkColumns}
        />
      </Box>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogToggle}>
        <DialogTitle>Confirm delete media file</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container direction="column">
              <Grid item>
                <Typography>
                  Are you sure you want to delete this media file?
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleDeleteDialogToggle}
            color="secondary"
            disabled={loadingDeleteMediaFile}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onDelete}
            disabled={loadingDeleteMediaFile}
            startIcon={
              loadingDeleteMediaFile && (
                <CircularProgress size={16} color="primary" />
              )
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AccountDetailMediaFilesTable
