import { Options, getLocalStorageUser } from './common'

export const APP_NAME = 'Recover'

export const TENANT_TYPE = 'http://debttrader.com/claims/tenant_type'
export const DISPLAY_NAME = 'http://debttrader.com/claims/display_name'
export const PERMISSION_INFO = 'http://debttrader.com/claims/permission_info'
export const BUSINESS_INFO = 'http://debttrader.com/claims/business_info'
export const IS_RESELLER = 'http://debttrader.com/claims/is_reseller'
export const CLIENT_INFO = 'http://debttrader.com/claims/client_info'
export const EMAIL = 'http://debttrader.com/claims/user_email'

export const INTERNAL = 'internal'
export const EXTERNAL = 'external'
export const BUYER = 'Buyer'
export const SELLER = 'Seller'

export const orders: Options[] = [
  { label: 'Pre-assignment', value: '0' },
  { label: 'Primary', value: '1' },
  { label: 'Secondary', value: '2' },
  { label: 'Tertiary', value: '3' },
  { label: 'Quad', value: '4' },
  { label: 'Post-Assignment', value: '6' },
]

export const CHARGE_OFF_STRATEGY_STAGE: Options = {
  label: 'Charge-Off',
  value: '5',
}

export const USER_LOCAL = getLocalStorageUser()

export const PERMISSION_INFO_002 =
  'http://debttrader.com/claims/permission_info/002'

export const MARKETPLACE_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/000'

export const CONTROL_PANEL_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/001'

export const CMS_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/002'

export const MONITOR_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/003'

export const RECOVER_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/004'

export enum PermissionCodeAccess {
  Recover = '004',
  Recover_Basic = '004.000',
  Recover_ViewAccountDetails = '004.000.000',
  Recover_PlaceAccounts = '004.000.001',
  Recover_RecallAccounts = '004.000.002',
  Recover_ReturnAccounts = '004.000.003',
  Recover_UpdateAccountInformation = '004.000.004',
  Recover_RequestAccountInformation = '004.000.005',
  Recover_CreateStrategies = '004.000.006',
  Recover_ViewPerformanceReports = '004.000.007',
  Recover_ViewVendorDueDiligence = '004.000.008',
  Recover_UpdateCreditorEligibility = '004.000.009',
  Recover_UpdateVendorEligibility = '004.000.010',
  Recover_UpdateStrategies = '004.000.011',
  Recover_DeleteStrategies = '004.000.012',
  Recover_ManuallyChargeOffAccounts = '004.000.013',
  Recover_AllowBalanceAdjustmentsUpload = '004.000.014',
  Recover_Billing = '004.001',
  Recover_ManageArAp = '004.001.000',
  Recover_ViewArAp = '004.001.001',
}

export enum AppsPermissionCodeAccess {
  Marketplace = '000',
  Control_Panel = '001',
  Compliance_Management_System = '002',
  Monitor = '003',
}

export enum ControlPanelCodeAccess {
  Control_Panel = '001',
  Control_Panel_Basic_Info = '001.002.000',
}

export enum MarketplaceCodeAccess {
  MarketPlace = '000',
  MarketPlace_Basic = '000.000',
}

export enum ComplianceCodeAccess {
  Compliance = '002',
  Compliance_Basic = '002.000',
}

export enum MonitorCodeAccess {
  Monitoring = '003',
  Monitoring_Basic = '003.000',
}

export enum FileTypeEnum {
  AccountFile = 1,
  RecallAccountFile = 2,
  TransactionFile = 3,
  UpdateAccountFile = 4,
  ChargeOffAccounts = 5,
  SendToStrategy = 6,
  MediaUploadManifest = 7,
  BalanceAdjustment = 8,
}

export enum AccountRetrievalType {
  Recall = 1,
  Return = 2,
}

export const SFTPServiceProvider = [
  { id: 'ExaVault', name: 'ExaVault' },
  { id: 'ShareFile', name: 'ShareFile' },
]

export enum DropzoneErrorCodes {
  INVALID_TYPE_FILE = 'file-invalid-type',
  FILE_TOO_BIG = 'file-too-large',
}

export enum ChargeOffBalanceEnum {
  AddInterestBalancetoPostChargeOffPrincipal = 'addInterestBalancetoPostChargeOffPrincipal',
  ZeroOutInterestBalanceAtChargeOff = 'zeroOutInterestBalanceAtChargeOff',
  AddFeeBalanceToPostChargeOffPrincipal = 'addFeeBalanceToPostChargeOffPrincipal',
  ZeroOutFeeBalanceAtChargeOff = 'zeroOutFeeBalanceAtChargeOff',
}
