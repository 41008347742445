/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react'
import { Content, Header } from 'everchain-uilibrary'
import { IComplaintGetOperations } from 'src/domain/features/get/complaint/complaint'
import { useCustomQuery } from 'src/infra/reactQuery'
import { useParams } from 'react-router-dom'
import ComplaintSummary from './Components/ComplaintSummary'
import ComplaintActions from './Components/ComplaintActions'

interface RouteParams {
  ecaid?: string
  complaintId?: string
}

interface ComplaintFormProps {
  complaintOperations?: IComplaintGetOperations
}

const ComplaintList: React.FC<ComplaintFormProps> = ({
  complaintOperations,
}) => {
  const { ecaid, complaintId } = useParams<RouteParams>()

  const { data: previewData, isLoading: loadingPreviewData } = useCustomQuery(
    ['getPreview', ecaid],
    async () => complaintOperations?.getPreview(ecaid!),
    { cacheTime: 0, enabled: !!ecaid && !complaintId }
  )

  const complaintData = previewData
  const loadingComplaintData = loadingPreviewData

  return (
    <Content id="create-complaint-search-accounts">
      <Header title="Complaint" subtitle="Complaint Detail" />
      <ComplaintActions
        complaintDto={complaintData}
        isLoading={loadingComplaintData}
      />
      <ComplaintSummary
        complaintDto={complaintData}
        isLoading={loadingComplaintData}
      />
    </Content>
  )
}

export default ComplaintList
