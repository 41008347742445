import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BlobServiceClient } from '@azure/storage-blob'
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Link as LinkMaterial,
} from '@mui/material'
import { DropzoneErrorCodes } from 'src/utils/constants'
import {
  ErrorToast,
  fParseXlsxFile,
  Icon,
  Button,
  SuccessToast,
  textSecondary,
} from 'everchain-uilibrary'
import {
  UploadStepper,
  DropUpload,
  UploadItem,
} from 'src/presentation/styles/mediaUpload'
import { useDropzone } from 'react-dropzone'
import LinearProgressWithLabel from './ProgressBar/LinearProgressWithLabel'
import { IMediaUploadGetOperations } from 'src/domain/features/get/mediaUpload/mediaUpload'
import { downloadBase64File } from 'src/utils/file/fileDownload'
import { useMutation } from '@tanstack/react-query'
import { IMediaUploadPostOperations } from 'src/domain/features/post/mediaUpload/mediaUpload'
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid'
import { IAuthContext } from 'src/context/Interfaces'
import { AuthContext } from 'src/context/AuthenticationContext'

interface PortfolioMediaFileFormProps {
  mediaUploadGetOperations: IMediaUploadGetOperations
  mediaUploadPostOperations: IMediaUploadPostOperations
}
const MediaFileForm: React.FC<PortfolioMediaFileFormProps> = ({
  mediaUploadGetOperations,
  mediaUploadPostOperations,
}) => {
  const { userPermissions } = useContext<IAuthContext>(AuthContext)
  const [hasNamingConvention, setHasNamingConvention] = useState<boolean>(true)
  const RecoverMediaFileContainer = 'recover'
  const [mediaFiles, setMediaFiles] = useState<any[]>([])
  const [manifestFiles, setManifestFiles] = useState<any[]>([])
  const [progress, setProgress] = useState(0)
  const [loadingSavePortfolioMediaFile, setLoadingSavePortfolioMediaFile] =
    useState<boolean>(false)
  const [
    loadingSavePortfolioMediaFileDetails,
    setLoadingSavePortfolioMediaFileDetails,
  ] = useState<boolean>(false)

  const MAX_SIZE_FILE = 5368709120 // 5GB
  useEffect(() => {
    const fetchNamingConvention = async () => {
      const namingConventions =
        await mediaUploadGetOperations?.getBusinessNamingConvention()
      setHasNamingConvention(namingConventions.length > 0)
    }
    if (userPermissions.type === 'Seller') {
      fetchNamingConvention()
    }
  }, [userPermissions, mediaUploadGetOperations])

  const onDropMedia = useCallback((acceptedFiles: any) => {
    setMediaFiles(acceptedFiles)
  }, [])

  const GetManifestTemplateFile = async () => {
    const template = await mediaUploadGetOperations?.getManifestTemplateFile()
    downloadBase64File(template)
  }

  const blobStorageUrls = async () => {
    const urls = await mediaUploadGetOperations?.getMediaFileBlobUrl()
    return urls
  }

  const onManifestDrop = useCallback((acceptedFiles: any) => {
    fParseXlsxFile(acceptedFiles[0]).then((result: any) => {
      const headers = [
        'LoanID',
        'FirstName',
        'LastName',
        'DocumentType',
        'DocumentNamingConvention',
      ] as string[]

      const notFound =
        result.data &&
        headers.filter((key) => {
          return !Object.keys(result.data[0]).find(
            (x: any) => x.toLowerCase().trim() === key.toLowerCase().trim()
          )
        })
      if (notFound.length > 0) {
        ErrorToast(
          `Error uploading manifest file. Header(s) not found ${notFound.join(
            ', '
          )}`
        )
        return
      }
      if (!result.passedValidation) {
        ErrorToast(`Error uploading manifest file. ${result.errorMessage}`)
        return
      }
      setManifestFiles(acceptedFiles)
    })
  }, [])

  const handleFileRejected = (data: any) => {
    const message =
      data[0].errors[0].code === DropzoneErrorCodes.INVALID_TYPE_FILE
        ? 'Only .zip files are accepted.'
        : data[0].errors[0].code === DropzoneErrorCodes.FILE_TOO_BIG
        ? 'File is over the 5GB limit.'
        : ''
    ErrorToast(`The file has been rejected. ${message}`)
  }

  const handleManifestFileRejected = (data: any) => {
    const message =
      data[0].errors[0].code === DropzoneErrorCodes.INVALID_TYPE_FILE
        ? 'Only .csv and .xlsx files are accepted.'
        : data[0].errors[0].code === DropzoneErrorCodes.FILE_TOO_BIG
        ? 'File is over the 5GB limit.'
        : ''
    ErrorToast(`The file has been rejected. ${message}`)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropMedia,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
    accept:
      'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip,.zip',
    onDropRejected: handleFileRejected,
  })

  const {
    getRootProps: getManifestRootProps,
    getInputProps: getManifestInputProps,
    isDragActive: isManifestDragActive,
  } = useDropzone({
    onDrop: onManifestDrop,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropRejected: handleManifestFileRejected,
  })

  function handleUploadFileRemove(): void {
    setMediaFiles([])
  }

  function handleUploadFileManifestRemove(): void {
    setManifestFiles([])
  }
  const savePortfolioMediaFileQuery = useMutation({
    mutationFn: async (params: any) => {
      setLoadingSavePortfolioMediaFileDetails(true)
      return mediaUploadPostOperations?.saveMediaFile(params)
    },
    onSuccess: (data: any) => {
      setLoadingSavePortfolioMediaFileDetails(false)
      SuccessToast('File uploaded successfully')
      setMediaFiles([])
      setManifestFiles([])
      cancelOperation()
      return data
    },
    onError: () => {
      setLoadingSavePortfolioMediaFileDetails(false)
      ErrorToast('Error uploading files')
      cancelOperation()
    },
  })

  const cancelOperation = () => {
    setLoadingSavePortfolioMediaFile(false)
  }

  const handleUploadFile = async (overwrite: any) => {
    const uuid = uuidv4()

    const blobName = `${'mediaFiles'}/${uuid}/${mediaFiles[0].name}`
    const urlData = await blobStorageUrls()
    if (!urlData?.getMediaFileBlobUrl) {
      ErrorToast('Error uploading files')
      return
    }
    setLoadingSavePortfolioMediaFile(true)
    const blobStorageClient = new BlobServiceClient(
      urlData?.getMediaFileBlobUrl
    )
    const containerClient = blobStorageClient.getContainerClient(
      RecoverMediaFileContainer
    )
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)
    const promiseList = []
    if (manifestFiles.length > 0) {
      const manifestBlobName = `${'mediaFiles'}/${uuid}/${
        manifestFiles[0].name
      }`
      const manifestBlobStorageClient = new BlobServiceClient(
        urlData?.getManifestFileBlobUrl
      )
      const manifestContainerClient =
        manifestBlobStorageClient.getContainerClient(RecoverMediaFileContainer)
      const manifestBlockBlobClient =
        manifestContainerClient.getBlockBlobClient(manifestBlobName)
      const manifestUploadPromise = manifestBlockBlobClient.uploadData(
        manifestFiles[0],
        {
          blobHTTPHeaders: {
            blobContentType:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          onProgress(fileProgress: any) {
            const percentageUploaded =
              (fileProgress?.loadedBytes / manifestFiles[0].size) * 100
            setProgress(percentageUploaded)
          },
        }
      )
      promiseList.push(manifestUploadPromise)
    }
    const mediaUploadPromise = blockBlobClient.uploadData(mediaFiles[0], {
      blobHTTPHeaders: { blobContentType: 'application/x-zip-compressed' },
      onProgress(fileProgress: any) {
        const percentageUploaded =
          (fileProgress?.loadedBytes / mediaFiles[0].size) * 100
        setProgress(percentageUploaded)
      },
    })
    promiseList.push(mediaUploadPromise)
    Promise.all(promiseList)
      .then(() => {
        let manifestDetails = {}
        if (manifestFiles.length > 0) {
          manifestDetails = {
            fileName: manifestFiles[0].name,
            contentType: manifestFiles[0].type,
          }
        }
        savePortfolioMediaFileQuery.mutate({
          overwrite,
          groupingFilesKey: uuid,
          details: {
            fileName: mediaFiles[0].name,
            contentType: mediaFiles[0].type,
          },
          manifestDetails: manifestDetails,
        })
      })
      .catch((error) => {
        console.log('error', error)
        cancelOperation()
        ErrorToast('Error uploading files')
      })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <UploadStepper style={{ marginTop: 10 }}>
            {loadingSavePortfolioMediaFile ||
            loadingSavePortfolioMediaFileDetails ? (
              <LinearProgressWithLabel value={progress} />
            ) : !mediaFiles.length || !manifestFiles.length ? (
              <Grid display={'flex'} flexDirection={'column'} gap={5}>
                {!manifestFiles.length ? (
                  <Box>
                    <DropUpload
                      isDragActive={isManifestDragActive}
                      {...getManifestRootProps()}
                    >
                      <input {...getManifestInputProps()} />
                      <div className="upload-placeholder">
                        <Icon name="CloudUpload" className="upload-icon" />{' '}
                        <strong>Choose a Manifest File </strong> or drop a
                        manifest file here to upload
                      </div>
                    </DropUpload>
                    <Typography
                      variant="caption"
                      display="block"
                      color={'black !important'}
                    >
                      Maximum allowed manifest file size is <strong>5GB</strong>
                      .{' '}
                      <LinkMaterial
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          GetManifestTemplateFile()
                        }}
                      >
                        Download Manifest Template
                      </LinkMaterial>
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
                {!mediaFiles.length ? (
                  <Box>
                    <DropUpload isDragActive={isDragActive} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="upload-placeholder">
                        <Icon name="CloudUpload" className="upload-icon" />{' '}
                        <strong>Choose File </strong> or drop a file here to
                        upload
                      </div>
                    </DropUpload>
                    <Typography
                      variant="caption"
                      display="block"
                      color={'black !important'}
                    >
                      Maximum allowed file size is <strong>5GB</strong>
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <></>
            )}
            {userPermissions.type === 'Seller' && !hasNamingConvention && (
              <Typography
                variant="caption"
                display="block"
                color={'red !important'}
              >
                There are no naming conventions set up. The default naming
                convention will be applied ([LoanID]_[DocumentType]).
              </Typography>
            )}
            {mediaFiles.length > 0 && (
              <UploadItem>
                {mediaFiles.map((file: any, idx) => (
                  <>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />{' '}
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleUploadFileRemove}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </>
                ))}
              </UploadItem>
            )}
            {manifestFiles.length > 0 && (
              <UploadItem>
                {manifestFiles.map((file: any, idx) => (
                  <>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />{' '}
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleUploadFileManifestRemove}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </>
                ))}
              </UploadItem>
            )}
          </UploadStepper>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              data-cy="add-attachment-button"
              type="submit"
              disabled={
                (!(
                  userPermissions.type === 'Buyer' &&
                  mediaFiles.length &&
                  manifestFiles.length
                ) &&
                  !(userPermissions.type !== 'Buyer' && mediaFiles.length)) ||
                loadingSavePortfolioMediaFile ||
                loadingSavePortfolioMediaFileDetails
              }
              onClick={() => {
                handleUploadFile(false)
              }}
            >
              <Icon name="Add" fontSize="small" />
              Add File
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default MediaFileForm
