import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IMediaUploadGetOperations } from 'src/domain/features/get/mediaUpload/mediaUpload'
import { ManifestBlobUrls, TemplateFile } from 'src/domain/models/mediaUpload'
import {
  MediaUploadGetBusinessNamingConvention,
  MediaUploadGetManifestTemplateFile,
  MediaUploadGetMediaFileBlobUrl,
} from './urls'

export class MediaUploadGetData implements IMediaUploadGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async getManifestTemplateFile(): Promise<TemplateFile> {
    const response = await this.httpClient.get(
      MediaUploadGetManifestTemplateFile
    )
    return response?.data
  }

  async getMediaFileBlobUrl(): Promise<ManifestBlobUrls> {
    const response = await this.httpClient.get(MediaUploadGetMediaFileBlobUrl)
    return response?.data
  }

  async getBusinessNamingConvention(): Promise<string[]> {
    const response = await this.httpClient.get(
      MediaUploadGetBusinessNamingConvention
    )
    return response?.data
  }
}
