/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'everchain-uilibrary'

import { Skeleton, TextField } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'

import { IBusinessGetOperations } from 'src/domain/features/get/business/business'

interface Options {
  label: string
  value: string
}
interface VendorParams {
  businessOperations?: IBusinessGetOperations
  onVendorUpdate: (vendor: any) => void
  selectedVendor?: string
}
const Vendors: React.FC<VendorParams> = ({
  businessOperations,
  onVendorUpdate,
  selectedVendor = '',
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [vendors, setVendors] = useState<any>()
  const [vendor, setVendor] = useState<string | undefined>(selectedVendor)
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: isInternal ? `${item.name} (${item.status})` : item.name,
        value: item.id,
      })
    )
    return newOptions
  }

  const GetVendors = async () => {
    setLoading(true)
    try {
      const vendorsResult = await businessOperations?.getVendorsData()
      setVendors(vendorsResult)
      const vendorOptions = getDropDownOptions(vendorsResult ?? [])
      if (!isInternal && vendorsResult?.length !== 0) {
        setVendor(vendorOptions[0].value)
        onVendorUpdate(vendorOptions[0].value)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const options = getDropDownOptions(vendors || [])

  useEffect(() => {
    GetVendors()
  }, [])

  if (loading) return <Skeleton width="380px" height={80} />
  return !options || options?.length === 0 ? (
    <></>
  ) : options && options.length > 1 ? (
    <Dropdown
      maxwidth="320px"
      width="320px"
      id="select-vendor"
      data-test-id="select-vendor"
      data-cy="select-vendor"
      options={options}
      value={vendor || undefined}
      onChange={(option?: Options) => {
        onVendorUpdate(option?.value)
        setVendor(option?.value)
      }}
      placeholder="Select a vendor"
    />
  ) : (
    <TextField
      style={{ width: '320px', maxWidth: '320px', marginBottom: '3px' }}
      data-test-id="input-vendor"
      id="input-vendor"
      disabled={true}
      fullWidth
      data-cy="input-vendor"
      label="Vendor"
      name="vendor"
      variant="standard"
      value={options[0].label ?? ''}
    />
  )
}

export default Vendors
