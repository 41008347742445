import {
  Colors,
  ErrorToast,
  ModalDialog,
  SuccessToast,
  Typography,
  ValidationResult,
} from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { AccountFileData, OverloadedVendor } from 'src/domain/models/accounts'
import { useMutation } from '@tanstack/react-query'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  accountPostOperations: IAccountPostOperations
}

const AccountFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  accountPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isCheckVendorCapacity, setIsCheckVendorCapacity] =
    useState<boolean>(false)
  const [vendorOverloadedCapacity, setVendorOverloadedCapacity] = useState<
    OverloadedVendor[]
  >([])
  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const mutationProcessAccountFile = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processAccountFile(
        params.creditorId,
        params.strategyId,
        params.accountFileData,
        params.file,
        params.overloadedVendors,
        params.isRestingArea
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const mutationcheckVendorAccountLoad = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.checkVendorAccountLoad(
        params.strategyId,
        params.accountFileData
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) {
        setVendorOverloadedCapacity(response.data)
        setIsCheckVendorCapacity(true)
      } else {
        handleConfirmProcessAccountFile()
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('creditorId') ||
    !stateData.hasOwnProperty('strategyId') ||
    !stateData.hasOwnProperty('fileUploaded') ||
    !stateData.hasOwnProperty('isRestingArea')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    ErrorToast('Error')
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.AccountFile)
  const { fileData, creditorId, strategyId, fileUploaded, isRestingArea } =
    stateData

  const mapToAccountFileObject = (input: any): AccountFileData => {
    const dataRow: AccountFileData = {
      rowIndex: input.rowindex,
      lender: input.lender,
      loanid: input.loanid,
      originalLoanAmount: input.fundamount,
      principalBalance: input.principalbalance,
      interestBalance: input.interestbalance,
      originalDate: input.defaultdate,
      writeOffDate: input.writeoffdate,
      defaultDate: input.defaultdate,
      firstDelinquencyDate: input.firstdelinquencydate,
      lastPaymentDate: input.lastpaymentdate,
      lastPaymentAmount: input.lastpaymentamount,
      dob: input.dob,
      firstName: input.firstname,
      lastName: input.lastname,
      mi: input.mi,
      ssn: input.ssn,
      address1: input.address1,
      address2: input.address2,
      city: input.city,
      state: input.state,
      zip: input.zip,
      country: input.country,
      product: input.product,
      affinity: input.affinity,
      apr: input.apr,
      accountSecured: input.accountsecured,
      additionalAccountNumber1: input.additionalaccountnumber1,
      additionalAccountNumber2: input.additionalaccountnumber2,
      additionalAccountNumber3: input.additionalaccountnumber3,
      bureauAccountCreditReportedTo: input.bureauaccountcreditreportedto,
      coBorrowerFlag: input.coborrowerflag,
      numberofRefinances: input.numberofrefinances,
      settlementAgreement: input.settlementagreement,
      settlementOfferAmount: input.settlementofferamount,
      buyersOnTitle: input.buyersontitle,
      seller: input.seller,
      consumerCreditScore_TimeofLending:
        input.consumercreditscore_timeoflending,
      homeStatus: input.homestatus,
      phnCountryCode: input.phncountrycode,
      phnCityCode: input.phncitycode,
      homePhone: input.homephone,
      mobilePhone: input.mobilephone,
      workPhone: input.workphone,
      phoneExtension: input.phoneextension,
      email: input.email,
      employer: input.employer,
      jobTitle: input.jobtitle,
      employmentStartDate: input.employmentstartdate,
      employmentEndDate: input.employmentenddate,
      payMethod: input.paymethod,
      employeeLastPaid: input.employeelastpaid,
      employeeSupervisor: input.employeesupervisor,
      employeeSupervisorPhone: input.employeesupervisorphone,
      payFrequency: input.payfrequency,
      dayOfPay: input.dayofpay,
      bankAccountType: input.bankaccounttype,
      bankName: input.bankname,
      routing: input.routing,
      bankAccountNumber: input.bankaccountnumber,
      fundDate: input.funddate,
      fundAmount: input.fundamount,
      feeBalance: input.feebalance,
      totalBalance: input.totalbalance,
      totalAmountPaid: input.totalamountpaid,
      writeOffReason: input.writeoffreason,
      lastPaymentStatus: input.lastpaymentstatus,
      initialReturnCode: input.initialreturncode,
      lastReturnCode: input.lastreturncode,
      lastWorkedDate: input.lastworkeddate,
      lastMissedPaymentDate: input.lastmissedpaymentdate,
      lastDueDate: input.lastduedate,
      idType: input.idtype,
      idNumber: input.idnumber,
      idState: input.idstate,
      storeName: input.storename,
      storeAddress: input.storeaddress,
      storeCity: input.storecity,
      storeState: input.storestate,
      merchantStoreID: input.merchantstoreid,
      totalPaidPriorToDefault: input.totalpaidpriortodefault,
      totalPaidPostDefault: input.totalpaidpostdefault,
      interestRate: input.interestrate,
      fee1: input.fee1,
      fee2: input.fee2,
      fee3: input.fee3,
      fee4: input.fee4,
      fee5: input.fee5,
      coBorrowerSSN: input['co-borrowerssn'],
      coBorrowerDOB: input['co-borrowerdob'],
      coBorrowerFirstName: input['co-borrowerfirstname'],
      coBorrowerLastName: input['co-borrowerlastname'],
      coBorrowerMI: input['co-borrowermi'],
      coBorrowerAddress1: input['co-borroweraddress1'],
      coBorrowerAddress2: input['co-borroweraddress2'],
      coBorrowerCity: input['co-borrowercity'],
      coBorrowerState: input['co-borrowerstate'],
      coBorrowerZip: input['co-borrowerzip'],
      coBorrowerCountry: input['co-borrowercountry'],
      coBorrowerHomeStatus: input['co-borrowerhomestatus'],
      coBorrowerPhnCountryCode: input['co-borrowerphncountrycode'],
      coBorrowerPhnCityCode: input['co-borrowerphncitycode'],
      coBorrowerHomePhone: input['co-borrowerhomephone'],
      coBorrowerMobilePhone: input['co-borrowermobilephone'],
      coBorrowerWorkPhone: input['co-borrowerworkphone'],
      coBorrowerPhoneExtension: input['co-borrowerphoneextension'],
      coBorrowerEmail: input['co-borroweremail'],
      coBorrowerEmployer: input['co-borroweremployer'],
      coBorrowerJobTitle: input['co-borrowerjobtitle'],
      coBorrowerEmployerAddress: input['co-borroweremployeraddress'],
      coBorrowerEmployerCity: input['co-borroweremployercity'],
      coBorrowerEmployerState: input['co-borroweremployerstate'],
      coBorrowerEmployerZip: input['co-borroweremployerzip'],
      coBorrowerEmployerPhone: input['co-borroweremployerphone'],
      coBorrowerDriverLicense: input['co-borrowerdriverlicense'],
      coBorrowerLanguage: input['co-borrowerlanguage'],
      coBorrowerMailingAddress1: input['co-borrowermailingaddress1'],
      coBorrowerMailingAddress2: input['co-borrowermailingaddress2'],
      coBorrowerMailingCity: input['co-borrowermailingcity'],
      coBorrowerMailingState: input['co-borrowermailingstate'],
      coBorrowerMailingCountry: input['co-borrowermailingcountry'],
      coBorrowerMailingZip: input['co-borrowermailingzip'],
      settlementOfferDate: input.settlementofferdate,
      settlementExpirationDate: input.settlementexpirationdate,
      paymentFrequency: input.paymentfrequency,
      dayPaymentWasScheduledForProcessing:
        input.daypaymentwasscheduledforprocessing,
      language: input.language,
      totalNumberOfNSFFees: input.totalnumberofnsffees,
      totalNSFFeesAmt: input.totalnsffeesamt,
      recoveryOpeningBalance: input.recoveryopeningbalance,
      outstandingRecoveryBalance: input.outstandingrecoverybalance,
      totalRecoveryPayments: input.totalrecoverypayments,
      mailingAddress1: input.mailingaddress1,
      mailingAddress2: input.mailingaddress2,
      mailingCity: input.mailingcity,
      mailingState: input.mailingstate,
      mailingCountry: input.mailingcountry,
      mailingZip: input.mailingzip,
      ref1Name: input.ref1name,
      ref1Phone: input.ref1phone,
      ref1Relationship: input.ref1relationship,
      ref2Name: input.ref2name,
      ref2Phone: input.ref2phone,
      ref2Relationship: input.ref2relationship,
      ref3Name: input.ref3name,
      ref3Phone: input.ref3phone,
      ref3Relationship: input.ref3relationship,
      ref4Name: input.ref4name,
      ref4Phone: input.ref4phone,
      ref4Relationship: input.ref4relationship,
      ref5Name: input.ref5name,
      ref5Phone: input.ref5phone,
      ref5Relationship: input.ref5relationship,
      refinanceAccount: input.refinanceaccount,
      accountCreditReported: input.accountcreditreported,
      lastStatementDate: input.laststatementdate,
      dateTransferredToCurrentOwner: input.datetransferredtocurrentowner,
      contractDate: input.contractdate,
      autoVIN: input.autovin,
    }

    return dataRow
  }

  const onSubmitFile = () => {
    if (isRestingArea) {
      handleConfirmProcessAccountFile()
    } else {
      setIsFetching(true)
      const accountFileData: AccountFileData[] = []
      fileData.forEach((x: any) => {
        const loweredCaseObject = transformPropertiesToLowercase(x)
        accountFileData.push(mapToAccountFileObject(loweredCaseObject))
      })

      const params = {
        strategyId: strategyId,
        accountFileData,
      }
      mutationcheckVendorAccountLoad.mutate(params)
    }
  }

  const handleConfirmProcessAccountFile = () => {
    setIsFetching(true)
    setIsCheckVendorCapacity(false)
    const accountFileData: AccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      accountFileData.push(mapToAccountFileObject(loweredCaseObject))
    })

    const params = {
      creditorId,
      strategyId: strategyId,
      accountFileData,
      file: fileUploaded,
      overloadedVendors: !isRestingArea
        ? vendorOverloadedCapacity.map((x) => x.vendorName)
        : null,
      isRestingArea: isRestingArea,
    }

    mutationProcessAccountFile.mutate(params)
  }

  return (
    <>
      <ValidationResult
        fileMap={fileMap}
        fileOptions={{
          validateTotalBalance: true,
          formatDate: profileCountry(),
        }}
        fileData={fileData}
        processmentErrors={processmentErrors}
        isFetchingProceed={isFetching}
        onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
        onClickProceedButton={onSubmitFile}
      />

      <ModalDialog
        header="Confirm Account Placement"
        isOpen={isCheckVendorCapacity}
        buttonCancelText="Cancel"
        buttonOkText="Confirm"
        onClose={() => setIsCheckVendorCapacity(false)}
        onContinue={handleConfirmProcessAccountFile}
        isLoading={isFetching}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 14 }}
        >
          {vendorOverloadedCapacity.map((x, index) => (
            <p key={index}>
              Proceeding with current strategy will make agency {x.vendorName}{' '}
              exceed 90% of their capacity of {x.maxAccountCapacity} accounts.
            </p>
          ))}
        </Typography>
      </ModalDialog>
    </>
  )
}

export default AccountFileValidation
