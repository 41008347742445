/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { ModalDialog } from 'everchain-uilibrary'
import MediaFileForm from './MediaFileForm'
import { makeMediaUploadPostData } from 'src/main/factories/feature/post/MediaUpload'
import { makeMediaUploadGetData } from 'src/main/factories/feature/get/MediaUpload'

interface UploadModalProps {
  open: boolean
  setOpenUploadModal: any
}

const UploadModal: React.FC<UploadModalProps> = ({
  open,
  setOpenUploadModal,
}: UploadModalProps) => {
  return (
    <>
      <ModalDialog
        isOpen={open}
        hideOkButton={true}
        hideCancelButton={true}
        onClose={() => {
          setOpenUploadModal(false)
        }}
        header="Bulk Media Files Upload"
        style={{ minWidth: '450px', minHeight: '300px' }}
      >
        <MediaFileForm
          mediaUploadGetOperations={makeMediaUploadGetData()}
          mediaUploadPostOperations={makeMediaUploadPostData()}
        ></MediaFileForm>
      </ModalDialog>
    </>
  )
}

export default UploadModal
