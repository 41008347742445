/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'everchain-uilibrary'

import { Skeleton, TextField } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'

import { IBusinessGetOperations } from 'src/domain/features/get/business/business'

interface Options {
  label: string
  value: string
}
interface CreditorParams {
  businessOperations?: IBusinessGetOperations
  onCreditorsUpdate: (creditors: any, creditorName?: any) => void
  selectedCreditor?: string
  origin?: string
}

const Creditors: React.FC<CreditorParams> = ({
  businessOperations,
  onCreditorsUpdate,
  selectedCreditor = '',
  origin = '',
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [creditors, setCreditors] = useState<any>()
  const [creditor, setCreditor] = useState<string | undefined>(selectedCreditor)
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: isInternal ? `${item.name} (${item.status})` : item.name,
        value: item.id,
      })
    )
    return newOptions
  }

  const GetCreditors = async () => {
    setLoading(true)
    try {
      const creditorsResult = await businessOperations?.getCreditorsData()

      const filterCreditors =
        origin === 'RestingArea'
          ? creditorsResult?.filter((f: any) => f.businessType !== 'Buyer')
          : creditorsResult

      setCreditors(filterCreditors)
      const creditorOptions = getDropDownOptions(filterCreditors ?? [])
      if (!isInternal && filterCreditors?.length !== 0) {
        setCreditor(creditorOptions[0]?.value)
        onCreditorsUpdate(creditorOptions[0]?.value, creditorOptions[0]?.label)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const options = getDropDownOptions(creditors || [])

  useEffect(() => {
    GetCreditors()
  }, [])

  if (loading) return <Skeleton width="330px" height={80} />
  return !options || options?.length === 0 ? (
    <></>
  ) : options && options.length > 1 ? (
    <Dropdown
      id="creditor-dropdown"
      maxwidth="300px"
      width="300px"
      options={options}
      value={creditor || undefined}
      onChange={(option?: Options) => {
        setCreditor(option?.value)
        onCreditorsUpdate(option?.value, option?.label)
      }}
      placeholder="Select a creditor"
    />
  ) : (
    <TextField
      style={{ width: '300px', maxWidth: '300px', marginBottom: '3px' }}
      data-test-id="input-creditor"
      id="input-creditor"
      disabled={true}
      fullWidth
      label="Creditor"
      name="creditor"
      variant="standard"
      value={options[0].label ?? ''}
    />
  )
}

export default Creditors
